// GetStartedForm.tsx

import React from "react";
import useGetStartedForm from "../../hooks/useGetStartedForm";
import TOLAEligibilityDescription from "../TOLAEligibilityDescription/TOLAEligibilityDescription";
import EligibilityDescription from "../EligibilityDescription/EligibilityDescription";

interface GetStartedFormProps {
  isTOLA: boolean;
}

const GetStartedForm: React.FC<GetStartedFormProps> = ({ isTOLA }) => {
  const {
    inviteCode,
    validationError,
    selectedOption,
    selfNomConfirmed,
    loading,
    error,
    handleInputChange,
    handleSubmit,
    setSelectedOption,
    setSelfNomConfirmed,
  } = useGetStartedForm(isTOLA);

  return (
    <form className="max-w-3xl mx-auto mt-5" onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          {/* Button options for selecting invite code or self-nomination */}
          <div className="flex justify-center mb-6">
            <button
              className={`px-4 py-2 rounded-md font-semibold shadow-sm mr-4 ${
                selectedOption === "inviteCode"
                  ? "bg-primary text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
              onClick={() => setSelectedOption("inviteCode")}
              type="button"
            >
              I have an Invite Code
            </button>
            <button
              className={`px-4 py-2 rounded-md font-semibold shadow-sm ${
                selectedOption === "selfNom"
                  ? "bg-primary text-white"
                  : "bg-gray-300 text-gray-600"
              }`}
              onClick={() => setSelectedOption("selfNom")}
              type="button"
            >
              I am Self-Nominating
            </button>
          </div>

          {/* Invite code form field */}
          {selectedOption === "inviteCode" && (
            <div className="sm:col-span-6">
              <label
                className="block text-sm font-medium leading-6 text-gray-900"
                htmlFor="invite-code"
              >
                Invite Code
              </label>
              <div className="mt-2">
                <input
                  autoComplete="off"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm
                      ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                      focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  id="invite-code"
                  name="invite-code"
                  onChange={handleInputChange}
                  placeholder="Enter your invite code"
                  type="text"
                  value={inviteCode}
                />
              </div>
              {validationError && (
                <p className="text-red-500 text-sm mt-2">{validationError}</p>
              )}
            </div>
          )}

          {selectedOption === "selfNom" && (
            <div className="sm:col-span-6">
              {isTOLA ? (
                <TOLAEligibilityDescription />
              ) : (
                <EligibilityDescription />
              )}
              <div className="mt-4 flex items-center">
                <input
                  checked={selfNomConfirmed}
                  className="mr-2"
                  id="selfNomConfirm"
                  onChange={() => setSelfNomConfirmed(!selfNomConfirmed)}
                  type="checkbox"
                />
                <label
                  className="text-sm text-gray-900"
                  htmlFor="selfNomConfirm"
                >
                  Yes, I confirm I meet the eligibility requirements
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {error && <div className="text-red-500 text-sm mt-2">{error}</div>}

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm 
              focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                loading ||
                (selectedOption === "inviteCode" &&
                  (!inviteCode.trim() || validationError)) ||
                (selectedOption === "selfNom" && !selfNomConfirmed)
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-primary text-white hover:bg-primary-light"
              }`}
          disabled={
            loading ||
            (selectedOption === "inviteCode" &&
              (!inviteCode.trim() || validationError !== null)) ||
            (selectedOption === "selfNom" && !selfNomConfirmed)
          }
          type="submit"
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default GetStartedForm;
