import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import Header from "../../../components/Header/Header";

interface UploadResult {
  success: boolean;
  message?: string;
  data?: any;
}

const SelfNomTranscriptUpload: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null);
  const joinSessionUuid = localStorage.getItem("joinSessionUuid");

  // Handle file selection
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  // Configure Dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".jpg", ".jpeg", ".png"],
      "application/msword": [".doc", ".docx"],
    },
  });

  // Handle file upload
  const handleUpload = async () => {
    setUploading(true);
    setUploadSuccess(null);

    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    try {
      const response = await axios.post<UploadResult>(
        `/api/public/join-session/${joinSessionUuid}/upload-transcript`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );
      setUploadSuccess(response.data.success);
      setFiles([]); // Clear files after successful upload
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadSuccess(false);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
      style={{
        backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
      }}
    >
      <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl relative">
        <Header />
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-primary mb-2">
            Upload Transcript
          </h2>
          <p className="text-sm text-gray-500">
            Drag and drop or select files to upload your transcript.
          </p>
        </div>

        <div
          {...getRootProps()}
          className={`p-10 border-dashed border-2 rounded-lg text-center ${
            isDragActive ? "border-indigo-600" : "border-gray-300"
          }`}
        >
          <input {...getInputProps()} />
          <FaCloudUploadAlt className="text-primary text-4xl mb-3" />
          <p className="text-gray-600">
            {isDragActive
              ? "Drop the files here ..."
              : "Drag & drop files here, or click to select files"}
          </p>
        </div>

        {files.length > 0 && (
          <div className="mt-6">
            <h4 className="text-lg font-semibold mb-2">Files to Upload:</h4>
            <ul className="space-y-1">
              {files.map((file, index) => (
                <li className="text-gray-700" key={index}>
                  {file.name} - {(file.size / 1024).toFixed(2)} KB
                </li>
              ))}
            </ul>
          </div>
        )}

        {files.length > 0 && (
          <button
            className={`mt-6 w-full px-6 py-3 rounded-lg ${
              uploading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-indigo-600 text-white hover:bg-indigo-700"
            }`}
            disabled={uploading}
            onClick={handleUpload}
          >
            {uploading ? "Uploading..." : "Upload Files"}
          </button>
        )}

        {uploadSuccess !== null && (
          <div
            className={`mt-4 p-3 rounded-lg text-center ${
              uploadSuccess
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {uploadSuccess
              ? "Files uploaded successfully!"
              : "Failed to upload files."}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelfNomTranscriptUpload;
