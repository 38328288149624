import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postPaymentHandlerApi } from "../../../../api/postPaymentHandler/postPaymentHandlerApi";
import Loader from "../../../../components/Loader";
import { PostPaymentHandlerRequest } from "../../../../api/postPaymentHandler/types";

const PostPaymentHandler: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const joinSessionUuid = localStorage.getItem("joinSessionUuid");
    const searchParams = new URLSearchParams(location.search);
    const paymentIntent = searchParams.get("payment_intent");
    const paymentIntentClientSecret = searchParams.get(
      "payment_intent_client_secret",
    );
    const redirectStatus = searchParams.get("redirect_status");

    if (
      joinSessionUuid &&
      paymentIntent &&
      paymentIntentClientSecret &&
      redirectStatus
    ) {
      const requestData: PostPaymentHandlerRequest = {
        paymentIntent,
        paymentIntentClientSecret,
        redirectStatus,
      };

      postPaymentHandlerApi(joinSessionUuid, requestData)
        .then((response) => {
          console.log("Payment processing successful:", response.data);

          // Check eligibility and navigate accordingly
          if (response.data.isEligibleForUpgrade) {
            navigate(`/registration/upgrade?payment_intent=${paymentIntent}`);
          } else {
            navigate("/registration/congratulations");
          }
        })
        .catch((error) => {
          console.error("Payment processing failed:", error);
          // Optionally redirect to an error page or display an error message
        });
    } else {
      console.error("Missing required URL parameters or joinSessionUuid.");
      // Handle missing parameters, optionally redirect to an error page
    }
  }, [location.search, navigate]);

  return <Loader />;
};

export default PostPaymentHandler;
