import React from "react";

const TOLAEligibilityDescription: React.FC = () => {
  return (
    <>
      <p className="text-sm text-gray-900 mb-4">
        To be eligible for membership, you must:
      </p>
      <ul className="list-disc list-inside text-sm text-gray-700 space-y-2">
        <li>
          Be a current high school student who meets one of the following
          eligibility requirements:
        </li>
        <li>SAT score of 1300+; or</li>
        <li>ACT score of 27+; or</li>
        <li>Cumulative GPA of 3.5+</li>
        <li>
          You will be required to submit proof of eligibility either at the end
          of this process or by email within 30 days.
        </li>
      </ul>
    </>
  );
};

export default TOLAEligibilityDescription;
