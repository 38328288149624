import React from "react";
import NSCSTextInput from "../../../../components/NSCSTextInput/NSCSTextInput";
import NSCSSelect from "../../../../components/NSCSSelect/NSCSSelect";
import NSCSCheckbox from "../../../../components/NSCSCheckbox/NSCSCheckbox";
import SchoolSelect from "../SchoolSelect/SchoolSelect";
import useRegistration from "../../hooks/useRegistration";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StudentDetailsForm: React.FC = () => {
  const {
    formValues,
    validationErrors,
    stateOptions,
    countryOptions,
    hasErrors,
    graduationYears,
    handleInputChange,
    handleSchoolChange,
    handleSubmit,
    loading,
    submitting,
  } = useRegistration();

  const navigate = useNavigate();
  const isTOLA = process.env.REACT_APP_TOLA === "true"; // Check if TOLA

  const handleStartOver = () => {
    // Clear localStorage and navigate back to home page
    localStorage.removeItem("joinSessionUuid");
    navigate("/");
  };

  return (
    <form className="max-w-3xl mx-auto" onSubmit={handleSubmit}>
      <div className="space-y-12">
        {/* Personal Information Section */}
        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {/* First Name */}
            {loading ? (
              <div className="sm:col-span-2">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                autoComplete="given-name"
                className="sm:col-span-2"
                error={validationErrors.firstName}
                id="first-name"
                label="First Name"
                name="firstName"
                onChange={handleInputChange}
                value={formValues.firstName}
              />
            )}

            {/* Middle Name */}
            {loading ? (
              <div className="sm:col-span-2">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                className="sm:col-span-2"
                id="middle-name"
                label="Middle Name"
                name="middleName"
                onChange={handleInputChange}
                value={formValues.middleName}
              />
            )}

            {/* Last Name */}
            {loading ? (
              <div className="sm:col-span-2">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                autoComplete="family-name"
                className="sm:col-span-2"
                error={validationErrors.lastName}
                id="last-name"
                label="Last Name"
                name="lastName"
                onChange={handleInputChange}
                value={formValues.lastName}
              />
            )}

            {/* Conditionally render School/University or High School fields */}
            {!isTOLA ? (
              loading ? (
                <div className="sm:col-span-6">
                  <Skeleton height={56} />
                </div>
              ) : (
                // School/University Name
                <div className="sm:col-span-6">
                  <SchoolSelect
                    onChange={handleSchoolChange}
                    value={formValues.school}
                  />
                  {validationErrors.school && (
                    <p className="text-red-500 text-sm mt-1">
                      {validationErrors.school}
                    </p>
                  )}
                </div>
              )
            ) : (
              <>
                {/* High School Name */}
                {loading ? (
                  <div className="sm:col-span-6">
                    <Skeleton height={56} />
                  </div>
                ) : (
                  <NSCSTextInput
                    className="sm:col-span-6"
                    error={validationErrors.highSchoolName}
                    id="high-school-name"
                    label="High School Name"
                    name="highSchoolName"
                    onChange={handleInputChange}
                    value={formValues.highSchoolName}
                  />
                )}

                {/* Graduation Year */}
                {loading ? (
                  <div className="sm:col-span-3">
                    <Skeleton height={56} />
                  </div>
                ) : (
                  <NSCSSelect
                    className="sm:col-span-3"
                    error={validationErrors.highSchoolGraduationYear}
                    id="graduation-year"
                    label="Graduation Year"
                    name="highSchoolGraduationYear"
                    onChange={handleInputChange}
                    options={graduationYears}
                    placeholder="Select your graduation year"
                    value={formValues.highSchoolGraduationYear}
                  />
                )}

                {/* High School GPA */}
                {loading ? (
                  <div className="sm:col-span-3">
                    <Skeleton height={56} />
                  </div>
                ) : (
                  <NSCSTextInput
                    className="sm:col-span-3"
                    error={validationErrors.highSchoolGpa}
                    id="high-school-gpa"
                    label="High School GPA"
                    name="highSchoolGpa"
                    onChange={handleInputChange}
                    value={formValues.highSchoolGpa}
                  />
                )}
              </>
            )}

            {/* Address Line 1 */}
            {loading ? (
              <div className="sm:col-span-6">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                autoComplete="street-address"
                className="sm:col-span-6"
                error={validationErrors.address1}
                id="address1"
                label="Address Line 1"
                name="address1"
                onChange={handleInputChange}
                value={formValues.address1}
              />
            )}

            {/* Address Line 2 */}
            {loading ? (
              <div className="sm:col-span-6">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                className="sm:col-span-6"
                id="address2"
                label="Address Line 2"
                name="address2"
                onChange={handleInputChange}
                value={formValues.address2}
              />
            )}

            {/* City */}
            {loading ? (
              <div className="sm:col-span-6">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                autoComplete="address-level2"
                className="sm:col-span-6"
                error={validationErrors.city}
                id="city"
                label="City"
                name="city"
                onChange={handleInputChange}
                value={formValues.city}
              />
            )}

            {/* Country */}
            {loading ? (
              <div className="sm:col-span-2">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSSelect
                className="sm:col-span-2"
                error={validationErrors.country}
                id="country"
                label="Country"
                name="country"
                onChange={handleInputChange}
                options={countryOptions}
                value={formValues.country}
              />
            )}

            {/* Zipcode */}
            {loading ? (
              <div className="sm:col-span-2">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                autoComplete="postal-code"
                className="sm:col-span-2"
                error={validationErrors.zipcode}
                id="zipcode"
                label="Zipcode"
                name="zipcode"
                onChange={handleInputChange}
                value={formValues.zipcode}
              />
            )}

            {/* State/Province */}
            {loading ? (
              <div className="sm:col-span-2">
                <Skeleton height={56} />
              </div>
            ) : stateOptions.length > 0 ? (
              <NSCSSelect
                className="sm:col-span-2"
                error={validationErrors.state}
                id="state"
                label="State/Province"
                name="state"
                onChange={handleInputChange}
                options={stateOptions}
                value={formValues.state}
              />
            ) : (
              <NSCSTextInput
                autoComplete="address-level1"
                className="sm:col-span-2"
                error={validationErrors.state}
                id="state"
                label="State/Province"
                name="state"
                onChange={handleInputChange}
                value={formValues.state}
              />
            )}

            {/* Personal Email */}
            {loading ? (
              <div className="sm:col-span-3">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                autoComplete="email"
                className="sm:col-span-3"
                error={validationErrors.personalEmail}
                id="personal-email"
                label="Personal Email"
                name="personalEmail"
                onChange={handleInputChange}
                type="email"
                value={formValues.personalEmail}
              />
            )}

            {/* School Email */}
            {loading ? (
              <div className="sm:col-span-3">
                <Skeleton height={56} />
              </div>
            ) : (
              <NSCSTextInput
                className="sm:col-span-3"
                error={validationErrors.schoolEmail}
                id="school-email"
                label="School Email"
                name="schoolEmail"
                onChange={handleInputChange}
                type="email"
                value={formValues.schoolEmail}
              />
            )}

            {/* Agree to Policies */}
            {loading ? (
              <div className="sm:col-span-6">
                <Skeleton height={20} />
              </div>
            ) : (
              <NSCSCheckbox
                checked={formValues.agreeToPolicies}
                className="sm:col-span-6"
                error={validationErrors.agreeToPolicies}
                id="agree-to-policies"
                label={
                  <>
                    I understand and agree to the{" "}
                    <a
                      className="text-indigo-600 underline"
                      href="https://nscs.org/privacy-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      NSCS Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      className="text-indigo-600 underline"
                      href="https://nscs.org/lifetime-membership-policy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Lifetime Membership Policy
                    </a>
                  </>
                }
                name="agreeToPolicies"
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>

      {/* Submit and Start Over Buttons */}
      <div className="mt-6 flex items-center justify-end gap-x-6">
        {loading ? (
          <>
            <Skeleton height={40} width={100} />
            <Skeleton height={40} width={100} />
          </>
        ) : (
          <>
            <button
              className="text-sm font-semibold leading-6 text-gray-900"
              onClick={handleStartOver}
              type="button"
            >
              Start Over
            </button>
            <button
              className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                loading || hasErrors || submitting
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-primary text-white hover:bg-primary-light"
              }`}
              disabled={hasErrors || loading || submitting}
              type="submit"
            >
              {submitting ? "Submitting..." : "Save"}
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default StudentDetailsForm;
